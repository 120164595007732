.rotation-hint {
  position: absolute;
  width: auto;
  left: 50%;
  top: 65%;
  pointer-events: none;
  z-index: 3000;

  &-circle {
    display: block;
    background-color: rgba(255,255,255,0.6);
    border: 1px solid white;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    opacity: 0;
  }

  &-text {
    // display: none;
    display: block;
    position: fixed;
    bottom: 10%;
    left: 0;
    right: 0;
    text-align: center;
    pointer-events: none;
    opacity: 0;
    padding: 0 20%;
  }
}
