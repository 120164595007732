
.back-button {
  @extend .jumplink-dimensions;
  position: sticky;
  top: 1rem;
  // display: inline-flex;
  // align-items: center;
  // gap: 0.5rem;
  // padding: 4px 12px;
  background-color: black;
  color: white;
  // border-radius: 100px;
  // cursor: pointer;
  white-space: nowrap;
  // padding-right: 1rem !important;
  // line-height: 1;
  // font-size: var(--pill-size);

  // @media only screen and (min-width: 420px) {
  //   padding: 4px 20px;
  // }

  &:visited {
    color: white;
  }

  // svg {
  //   width: 16px;
  //   height: 28px;
  //   color: white;

  //   @include respond-to(small) {
  //     width: 28px;
  //   }
  // }
}
