.jumplink {
  display: block;
  position: relative;
  z-index: 150;
  margin-top: calc(100svh - 3.875rem);
  position: sticky;
  top: 1rem;
  user-select: none;

  &.directs-up {
    svg {
      transform: rotate(-180deg);
    }
  }

  &-label {
    line-height: 1;
  }

  &-dimensions {
    display: inline-flex;
    gap: 0.5rem;
    border-radius: 100px;
    font-size: var(--pill-size);
    // padding: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 1rem);
    align-items: center;
    cursor: pointer;
    padding: 8px 18px;

    @include respond-to(medium) {
      padding: 8px 24px;
    }
  }

  &-pill {
    @extend .jumplink-dimensions;
    // padding-right: 1rem;
    background-color: var(--local-foreground);
    border: 2px solid var(--local-foreground);
    color: var(--local-background);

    // @include respond-to(small) {
    //   gap: 1rem;
    //   padding: 0.5rem 1.5rem;
    // }

    svg {
      flex-shrink: 0;
      width: 28px;
      height: 28px;
      transition: transform 200ms;
      color: var(--local-background);

      // @include respond-to(small) {
      //   width: 28px;
      //   height: 28px;

      // }
    }

    &:visited {
      color: var(--local-background);
    }
  }
}
