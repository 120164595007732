/* By Athlon Front-End Team */


/* CSS Reset */
@import 'base/reset';

/* CSS print */
@import 'base/print';

/* Fonts */
@import 'base/fonts';

/* Core variables */
@import 'base/vars';

/* Base */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  &::selection {
    color: white;
    background: #7DA2FF;
    border-radius: 4px;
  }
}

a, a:visited, a:hover, a:active {
  color: inherit;
}


/* Components */
@import '../components/siteintro/siteintro';
@import '../components/header/header';
@import '../components/button/button';
@import '../components/back-button/back-button';
@import '../components/subpageheader/subpageheader';
@import '../components/footer/footer';
@import '../components/article/article';
@import '../components/footnotes/footnotes';
@import '../components/webgl/webgl';
@import '../components/jumplink/jumplink';
@import '../components/topic/topic';
@import '../components/rotation-hint/rotation-hint';
@import '../components/gallery/gallery';
@import '../components/video/video';

/* Browser specific styles */
@import 'dirty/hacks';

/* Demo styles */

html {
  scroll-behavior: smooth; //is temporarily disabled in barba transition
}


body {
  background: #ffffff;
  // transition: background-color 800ms cubic-bezier(0.16, 1, 0.3, 1);
  transition: background-color 1200ms;
  transition-delay: 400ms;
  color: black;
  font-family: 'Repro', 'Helvetica', 'Arial', sans-serif;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.5;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;

  @include respond-to(medium) {
    font-size: 1rem;
  }
}

.resize-helper {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  height: 100lvh;
  // border: 10px solid red;
}

h1, h2, h3, h4, h5, h6, .topic-title, .logo {
  font-family: 'Repro Mono', monospace;
  font-weight: normal;
  line-height: 1.2;
}

h1 {
  font-size: var(--h1-size);
  margin-top: 5rem;
}

h2 {
  font-size: var(--h2-size);
}

h3 {
  font-size: var(--h3-size);
}

img, figure {
  width: 100%;
  display: inherit;
}

.menu {
  text-align: right;
}

.video,
.img {
  position: relative;
  display: block;
  --w: 1;
  --h: 1;
  padding-bottom: calc(100% / var(--w) * var(--h));
  background: var(--color-black);
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: black;
  background-color: #ffffff;
  z-index: 200;

  &.home {
    // margin-top: 100vh;
    position: relative;
    background-color: transparent;
    z-index: 10;
  }

  &.info, &.topic, &.alle-artikel, &.article, &.slideshow, &.methodik, &.impressum, &.imprint, &.legal {
    --local-background: white;
    --local-foreground: black;
  }
}

main {
  padding: 1rem;
  // min-height: 100vh;
}

.lil-gui {
  z-index: 5001 !important;
}

.topic-title {
  font-size: var(--h1-size);
  line-height: 1;
  font-weight: normal;
  color: var(--local-foreground);
  position: absolute;
  padding: 1rem 0;
  top: 25%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translate3d(0, -50%, 0);
  user-select: none;
  transition: transform 400ms, opacity 625ms;

  @include respond-to(small) {
    transform: translate3d(0, -40%, 0);
  }

  @include respond-to(medium) {
    transform: translate3d(0, -35%, 0);
  }

  &-line1, &-line2, &-line3 {
    overflow: hidden;
    position: relative;
    height: calc(var(--h1-size) * 1.2);
    margin-top: calc(var(--h1-size) * -0.2);
  }

  .line {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    padding: calc(var(--h1-size) * 0.1);
  }
}

.grid {
  --columns: 12;
  --gutter: 3rem;
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: 1fr;
}

.grid > .column {
  margin-bottom: var(--gutter);
}

.autogrid {
  --gutter: 3rem;
  --min: 10rem;
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: repeat(auto-fit, minmax(var(--min), 1fr));
  grid-auto-flow: dense;
}

@media screen and (min-width: 60rem) {
  body {
    --padding: 3rem;
  }

  .grid {
    grid-template-columns: repeat(12, 1fr);
  }
  .grid > .column {
    grid-column: span var(--columns);
  }

}


.burger-menu-visible {
  .topic-title {
    transform: translate(0, 90px);
  }
}

.jumplink, .logo {
  transition: opacity 625ms;
}
.nothome {
  .jumplink, .webgl, .point, .topic-title, .header, .header-burger-menu, .logo {
    opacity: 0;
  }
}

.siteintroactive {
  .jumplink, .point, .topic-title, .header a, .header-burger-menu, .switchview, .logo {
    opacity: 0;
  }
}
