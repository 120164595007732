.button {
  display: inline-block;
  position: relative;
  border-radius: 20px;
  background-color: var(--local-background);
  color: var(--local-foreground);
  padding: 0.5rem 1.5rem;
  margin: 1rem 0.5rem 0 0.5rem;

  &:visited {
    color: white;
  }

  &.secondary {
    background-color: transparent;
    border: 2px solid var(--local-foreground);
    color: var(--local-foreground);
  }
}
