// @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500&display=swap');

/**
 * Fonts
*/


@font-face {
  font-family: 'Repro Mono';
  src: url("../../assets/fonts/EduReproMono-Medium.woff") format("woff"),
  url("../../assets/fonts/EduReproMono-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Repro';
  src: url("../../assets/fonts/EduRepro-Regular.woff") format("woff"),
  url("../../assets/fonts/EduRepro-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Repro';
  src: url("../../assets/fonts/EduRepro-RegularItalic.woff") format("woff"),
  url("../../assets/fonts/EduRepro-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Repro';
  src: url("../../assets/fonts/EduRepro-Medium.woff") format("woff"),
  url("../../assets/fonts/EduRepro-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Repro';
  src: url("../../assets/fonts/EduRepro-MediumItalic.woff") format("woff"),
  url("../../assets/fonts/EduRepro-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Repro';
  src: url("../../assets/fonts/EduRepro-Bold.woff") format("woff"),
  url("../../assets/fonts/EduRepro-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Repro';
  src: url("../../assets/fonts/EduRepro-BoldItalic.woff") format("woff"),
  url("../../assets/fonts/EduRepro-BoldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}



