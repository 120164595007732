.webgl {
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
  z-index: 50;
  max-width: 100vw;
  transition: transform 400ms, opacity 500ms;
}

.loading-bar {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background: #ffffff;
  transform: scaleX(0.3);
  transform-origin: top left;
  transition: transform 0.5s;
}

.loading-bar.ended {
  transform: scaleX(0);
  transform-origin: 100% 0;
  transition: transform 1.5s ease-in-out;
}

.points {
  display: block;
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
}

.point {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  pointer-events: none;
  width: 1px;
  height: 1px;
  transition: top 400ms, opacity 0.625s;

  .label {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    transform: translate3d(-50%, 0, 0);
    color: black;
    background-color: white;
    border-radius: 40px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 300ms, color 200ms, background-color 200ms;
    transition-delay: 0ms;
    user-select: none;
    cursor: pointer;
  }

  svg {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 3px;
    left: 2px;
    transition: transform 300ms;
    transition-delay: 100ms;

    .lightrays {
      stroke-dasharray: 4;
      stroke-dashoffset: 4;
      transition: none;
    }
  }

  .point-text {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px;
    gap: 10px;
    transform: translate(-50%, calc(-100% + 20px));
    width: calc(100vw - 2rem);
    max-width: 400px;
    color: black;
    background-color: white;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    text-align: left;

    @include respond-to('medium') {
      font-size: 24px;
      max-width: 500px;
    }

    h3 {
      font-size: 14px;
    }
  }

  &.visible {
    pointer-events: all;

    .label {
      transition-delay: 100ms;
      opacity: 1;
    }
  }

  &:hover {
    z-index: 105;

    svg .lightrays {
      stroke-dashoffset: -8;
      transition: stroke-dashoffset 2s cubic-bezier(.37,0,.13,1);
    }

    .point-text {
      display: flex;
    }
  }
}

.burger-menu-visible {
  .webgl {
    transform: translate(0, 160px);
  }

  .point {
    top: calc(50% + 170px);
  }
}
