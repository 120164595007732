//fyi: back button has its own component

.subpageheader {
  position: sticky;
  top: 1rem;
  display: flex;
  justify-content: center;
  gap: 10px;
  z-index: 20;
  margin-bottom: 8rem;

  @include respond-to(medium) {
    gap: 20px;
  }

  --action-color: #7DA2FF;

  // .back-button {
  //   padding-right: 1rem;
  // }

  .link-copy-button, .cite-button {
    // @extend .jumplink-pill;
    @extend .jumplink-dimensions;
    background-color: #F9F9F9;
    color: black;
    transition: color 200ms, background-color 200ms;
    font-size: var(--pill-size);
    // padding-right: 0.5rem;

    // @media only screen and (min-width: 450px) {
    //   padding-right: 1rem !important;
    // }

    svg {
      color: black;
      transition: color 200ms;
    }

    span {
      display: none;

      @media only screen and (min-width: 450px) {
        display: inline-block;
      }
    }

    &:hover {
      background-color: var(--action-color);
      color: white;

      svg {
        color: white;
      }
    }
  }

  .dialog {
    background-color: var(--action-color);
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: absolute;
    max-width: calc(100vw - 2rem);
    top: 4.2rem;
    opacity: 0;
    pointer-events: none;
    border-radius: 20px;
    transform: translate3d(0, 20px, 0);
    transition: transform 200ms, opacity 200ms;
    font-size: 14px;

    @include respond-to(small) {
      flex-direction: row;
      font-size: inherit;
    }

    @include respond-to(medium) {
      max-width: 600px;
    }

    &-copy-button {
      font-size: 14px;
      display: flex;
      gap: 10;
      padding: 8px 12px 8px 10px;
      background-color: white;
      border: 2px solid white;
      color: black;
      border-radius: 40px;
      cursor: pointer;
      transition: all 200ms;

      &:hover {
        background-color: transparent;
        color: white;
      }
    }

    &.is-visible {
      opacity: 1;
      pointer-events: auto;
      transform: translate3d(0, 0, 0);
    }
  }
}

.slideshow .subpageheader {
  margin-bottom: 4rem;
}
