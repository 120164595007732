.header {
  position: absolute;
  left: 50%;
  right: auto;
  top: 0;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  // background-color: rgba(255, 255, 255, 0.8);
  font-size: var(--pill-size);
  color: var(--local-foreground);
  z-index: 100;
  flex-direction: column-reverse;
  transform: translate(-50%, 0);
  transition: transform 400ms, opacity 500ms;

  &.is-hidden {
    transform: translate(-50%, calc(-100% + 40px + 2rem));
  }

  @include respond-to(large) {
    left: auto;
    right: 0;
    flex-direction: row;
    gap: 2rem;
    transform: none;

    &.is-hidden {
      transform: none;
    }
  }


  .switchview {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    padding: 8px 24px;
    border: 2px solid var(--local-foreground);
    border-radius: 100px;
    cursor: pointer;
    white-space: nowrap;
    line-height: 1;

    svg {
      width: 28px;
      height: 28px;
    }

    .neighborhood, .house {
      display: none;
    }

    &.is-neighborhood {
      .neighborhood {
        display: block;
      }
    }

    &.is-house {
      .house {
        display: block;
      }
    }
  }
}

.logo {
  font-weight: 500;
  position: absolute;
  top: 12px;
  left: 1rem;
  color: var(--local-foreground);
  z-index: 100;
  display: none;

  @include respond-to(medium) {
    display: block;
  }

  &:visited {
    color: var(--local-foreground);
  }
}


.header-burger-menu {
  position: absolute;
  z-index: 100;
  top: 12px;
  right: 0.5rem;
  cursor: pointer;
  color: var(--local-foreground);

  @include respond-to(medium) {
    right: 1rem;
  }

  @include respond-to(large) {
    display: none;
  }

  svg {
    padding: 6px 0;
    height: 40px;
    width: 40px;
  }

  .burger {
    opacity: 1;
  }

  .close {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &.is-open {
    .burger {
      opacity: 0;
    }

    .close {
      opacity: 1;
    }
  }
}
