
.article, .info, .legal, .slideshow {
  text-align: left;
  font-size: 18px;
  transition: background-color 500ms;

  @include respond-to('medium') {
    font-size: 20px;
  }

  --article-light-backdrop: #f6f6f6;

  &-def-max-width {
    position: relative;
    max-width: var(--max-width-m);
    // padding: 0 1rem;
    // margin: 2rem 1rem;
    margin: 0 1rem 2rem 1rem;
    width: auto;
    display: block;

    @include respond-to('medium') {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      padding: 0;
    }
  }

  p a {
    color: #4F7CD4;
    text-decoration: underline;
    text-underline-offset: 4px;
    // word-break: break-all;
    overflow-wrap: anywhere;

    &:after {
      content: ' ';
      width: 1rem;
      height: 1rem;
      display: inline-block;
      margin-left: 4px;
      margin-right: 4px;
      background-image: url('../../assets/images/external-link.svg');
      background-size: contain;
      transform: translateY(1px);
    }
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  u a {
    text-decoration: none;
  }

  main {
    padding: 0;
  }


  h2, h3 {
    @extend .article-def-max-width;
    font-family: 'Repro';
    margin-bottom: 1rem;
    line-height: 1.3;
  }

  h3 {
    font-weight: 500;
  }


  h1, #potentiale {
    @extend .article-def-max-width;
    position: relative;
    hyphens: auto;
    // width: 100%;
    margin-top: 2rem;
    font-family: 'Repro Mono';
    max-width: var(--max-width-m);
    line-height: 1.1;
    font-size: 2rem; // = 32px

    @include respond-to('medium') {
      margin-top: 4rem;
      font-size: 48px;
    }

    @include respond-to('large') {
      font-size: 64px;
    }

    @include respond-to('wide') {
      font-size: 95px;
    }
  }
  // h2 {
  //   font-family: 1.2rem;

  //   @include respond-to('medium') {
  //     font-family: 50px;
  //   }
  // }

  p {
    @extend .article-def-max-width;
    line-height: 140%;
    overflow: hidden;
    font-size: 16px;

    @include respond-to('wide') {
      font-size: 20px;
    }
  }

  .single-image {
    @extend .article-def-max-width;
    position: sticky;
    top: 4.2rem;

    figcaption {
      margin-top: 6px;
      font-size: 12px;
    }
  }

  .subline {
    @extend .article-def-max-width;
    position: relative;
    // width: 100%;
    max-width: var(--max-width-m);
    // margin: 1rem auto 0 auto;
    font-size: 20px;
    font-family: 'Repro';
    font-weight: normal;

    @include respond-to('medium') {
      font-size: 26px;
      margin: 2rem auto 0 auto;
    }
  }

  .mainimage {
    @extend .article-def-max-width;
    position: relative;
    // width: 100%;
    max-width: var(--max-width-m);
    margin: 2rem 1rem;
    aspect-ratio: 2 / 1;
    overflow: hidden;
    border-radius: 20px;
    overflow: hidden;

    @include respond-to('medium') {
      margin: 4rem auto;
    }

    img {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  .article-link {
    background-color: var(--article-light-backdrop);
    color: black;
    border-radius: 10px;
    padding: 8px 14px;
    display: inline-block;
  }

  .article-link-wrapper {
    @extend .article-def-max-width;
  }

  //quote
  .quote {
    @extend .article-def-max-width;
    margin-top: 4rem;
    position: relative;
    font-size: 1.8rem;

    @include respond-to(medium) {
      margin-top: 6rem;
      margin-bottom: 6rem;
    }

    mark {
      background-color: transparent;
    }

    span {
      opacity: 0.2;
    }

    &-text {
      position: relative;
      font-family: 'Repro Mono';
      line-height: 1.2;
      margin-left: 36px;
      font-size: 1rem;
      max-width: calc(100vw - 2rem);
      display: block;

      &:before {
        display: block;
        position: absolute;
        content: " ";
        border-left: 2px solid black;
        border-bottom: 2px solid black;
        border-bottom-left-radius: 10px;
        width: 24px;
        height: 24px;
        left: -36px;
        top: -10px;
      }


      @include respond-to('small') {
        font-size: 1.2rem;
      }

      @include respond-to('medium') {
        margin-left: 0px;
        font-size: 44px;

        &::before {
          width: 28px;
          height: 28px;
          border-bottom-left-radius: 10px;
          left: -52px;
          top: 0;
        }
      }

      @include respond-to('wide') {
        margin-left: 0px;
        font-size: 74px;

        &::before {
          width: 48px;
          height: 48px;
          border-bottom-left-radius: 20px;
          left: -72px;
        }
      }
    }

    footer {
      padding: 5px 10px;
      font-size: 14px;
      display: inline-block;
      background-color: var(--article-light-backdrop);
      border-radius: 10px;
      margin-left: 36px;
      margin-top: 1rem;

      @include respond-to('medium') {
        margin-left: 0;
      }
    }

    &-right {
      text-align: right;

      .quote-text {
        margin-left: 0;
        margin-right: 36px;

        &:before {
          border-left: none;
          border-right: 2px solid black;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 10px;
          left: auto;
          right: -36px;
        }

        @include respond-to('medium') {
          margin-left: 0px;
          margin-right: 0px;

          &:before {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 10px;
            left: auto;
            right: -52px;
          }
        }

        @include respond-to('wide') {
          margin-left: 0px;
          margin-right: 0px;

          &:before {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 20px;
            left: auto;
            right: -72px;
          }
        }
      }

      footer {
        margin-left: 0;
        margin-right: 36px;

        @include respond-to('medium') {
          margin-left: 0px;
          margin-right: 0px;
        }
      }
    }
  }


  // definition
  .definition {
    @extend .article-def-max-width;
    background-color: var(--article-light-backdrop);
    padding: 30px;
    border-radius: 20px;
    // max-width: 600px;
    margin: 2rem 1rem;

    @include respond-to('medium') {
      margin:  0 auto 2rem auto;
    }
  }

  .definition svg {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
    flex-shrink: 0;
  }

  .definition-title-wrapper {
    display: flex;
    align-items: center;
  }

  .definition-title {
    // font-size: 1.6rem;
    font-size: 28px;
    font-weight: 500;
    background-color: transparent;
  }

  .definition-text {
    background-color: transparent;
    width: 100%;
    display: inline-block;
    margin-top: 10px;
    font-size: 16px;
  }




  //keyfigure

  .keyfigure {
    @extend .article-def-max-width;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;

    mark {
      background-color: transparent;
    }

    span {
      display: inline-block
    }
  }

  .keyfigure-title {
    background-color: var(--article-light-backdrop);
    padding: 8px 36px;
    border-radius: 120px;
    text-align: center;
    font-size: 2rem;
    font-family: 'Repro Mono';
    overflow: hidden;
    display: inline-block;

    @include respond-to('medium') {
      font-size: 74px;
    }
  }

  .keyfigure-text {
    background-color: transparent;
    width: 100%;
    display: inline-block;
    margin-top: 1rem;
    text-align: center;
  }

  .grid-66 {
    padding: 0 1rem;
    max-width: var(--max-width-m);
    // @extend .article-def-max-width;
    @include respond-to('medium') {
      padding: 0;
      margin: 2rem auto;
      width: 80%;
    }
  }

  .grid .column-6 {
    .keyfigure, .quote, .definition, h2, h3, p, .single-image, .article-link-wrapper, .video-container {
      width: auto;
      margin-left: 0;
      margin-right: 0;
    }

    .quote {
      margin-top: 0;

      &-text {
        // font-size: 1.2rem;

        &:before {
          // top: -24px;
        }

        @include respond-to('medium') {
          font-size: 37px;
        }
      }
    }
  }

}

#matomo-opt-out {
  //@extend .article-def-max-width;
  font-size: 2rem;
  font-family: 'Repro Mono';

  p {
    width: 100%;
  }

  input {
    appearance: auto;
    margin-right: 10px;
  }

  label {
    font-size: inherit;
  }
}
