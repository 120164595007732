.gallery {
  // border: 1px solid pink;
  max-width: 100vw;
  display: block;
  width: 100%;
  margin: 4rem 0;
  opacity: 0;
  transition: opacity 500ms;

  .swiper-wrapper {
    align-items: center;
    // border: 1px solid red;
  }

  .swiper-button-next, .swiper-button-prev {
    color: black;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    display: none;
    height: 48px !important;
    padding: 0 32px;

    &:after {
      font-size: 48px;
    }

    @include respond-to('medium') {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .swiper-slide {
    width: auto;
    max-width: calc(100% - 8rem);
    // height: 80vh;
    text-align: center;
    font-size: 18px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding: 0 1rem;
    // border: 1px solid blue;

    *::selection {
      background-color: transparent;
      color: inherit;
    }
  }

  .swiper-slide-text {
    max-width: calc(100% - 6rem);
    text-align: left;
    align-items: flex-start;

    h1, p {
      margin-left: 0;
      margin-right: 0;
    }

    h1 {
      margin-top: 0;
    }

    p {
      font-size: 16px;
    }

    @include respond-to('small') {
      max-width: calc(100% - 8rem);
      p {
        font-size: inherit;
      }
    }

    @include respond-to('wide') {
      max-width: var(--max-width-s);
    }
  }

  .swiper-slide img {
    display: block;
    // width: auto;
    // height: 100%;
    height: 80vh;
    object-fit: cover;
    border-radius: 20px;
  }
  .swiper-slide figcaption {
    flex-shrink: 0;
    margin: 14px 0;
    font-size: 12px;
    opacity: 0;
    transition: opacity 200ms;
  }

  .swiper-slide-active figcaption {
    opacity: 1;
  }

  @media (orientation: portrait) {
    .swiper-slide img {
      height: auto;
      object-fit: contain;
      //  max-width: calc(100vw - 2rem);
    }
  }
}
