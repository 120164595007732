/**
 * Variables
*/

$body-background-color: #333;
$foreground-color: #fff;
/* Components */

$breakpoints: (
  small: 480px,
  medium: 720px,
  large: 960px,
  wide: 1200px,
  xwide: 1400px
);


/* Mixins, helpers and functions */
@import './mixins';


//CSS Variables
:root {
  --global-padding: 1rem;

  --copy-small-size: 1rem;
  --copy-size: 1.2rem;
  --copy-large-size: 1.3rem;

  --pill-size: 14px;

  --h1-size: 10vw;
  // --h2-size: 1.4rem;
  --h2-size: 26px; //32 before
  // --h3-size: 1.2rem;
  --h3-size: 20px;

  --button-text-size: 1.2rem;
  --button-large-text-size: 2rem;

  --local-background: white;
  --local-foreground: black;

  --max-width-s: 800px;
  --max-width-m: 1200px;
  --max-width-l: 1400px;


  @include respond-to(small) {
    --button-text-size: 1.4rem;
    --pill-size: 16px;
  }

  @include respond-to(medium) {
    --copy-small-size: 1.2rem;
    --copy-size: 1.5rem;
    --copy-large-size: 1.6rem;

    --pill-size: 20px;

    --h1-size: 10vw;
    --h2-size: 40px;
    --h3-size: 26px;

    --button-text-size: 2.2rem;
    --button-large-text-size: 3.5rem;
  }

  @include respond-to(large) {
    --global-padding: 2.5vw;
    --copy-size: 1.6vw;
    --copy-large-size: 2vw;
    --button-text-size: 3rem;
  }

  @include respond-to(wide) {
    --h1-size: 100px;
    --copy-small-size: 1.2vw;
    // --h3-size: 2vw;
  }

  @include respond-to(xwide) {
    --h1-size: 8vw;
  }
}
