.video-container {
  @extend .article-def-max-width;
  // margin-bottom: 2rem;
  position: relative;

  .cover {
    aspect-ratio: 16 / 9;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    background-color: var(--article-light-backdrop);
    border-radius: 20px;
    text-align: center;
    padding: 1rem;

    h2 {
      margin: 0;
    }

    p {
      font-size: 14px;
      max-width: 600px;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  .video-content {
    position: absolute;
    inset: 0;
    z-index: 10;
    overflow: hidden;
    border-radius: 10px;
  }

  .load-video {
    font-size: var(--pill-size);
    background-color: var(--local-foreground);
    color: var(--local-background);
    white-space: nowrap;
    max-width: calc(100% - 1rem);
    border-radius: 100px;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    display: inline-flex;
    overflow: hidden;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
