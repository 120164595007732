//fyi: animations are handled in the House.js

.siteintro {
  position: fixed;
  z-index: 2000;
  // border: 4px solid red;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  height: 100lvh;
  overflow-y: scroll;
  scroll-behavior: smooth;

  &-pills {
    display: flex;
    gap: 10px;
    margin-top: 1rem;
    width: 100%;
    padding: 0 1rem;

    @include respond-to('small') {
      gap: 20px;
      width: 80%;
      padding: 0;
    }

    @include respond-to('wide') {
      width: 60%;
      width: 1000px;
    }
  }

  &-jumplink {
    @extend .jumplink-pill;
  }

  &-skip {
    @extend .jumplink-pill;
    border: 2px solid black !important;
    background-color: transparent !important;
    color: black !important;
    cursor: pointer;
    // padding-left: 1rem !important;

    // @include respond-to('small') {
    //   padding-left: 1.5rem !important;
    // }
  }

  &-skip, &-jumplink {
    @media only screen and (max-width: 348px) {
      padding: 4px 8px !important;
    }
  }

  &-wrapper {
    padding-bottom: calc(100vh + 200px); //offset for mobile trigger (ios url-bar)
    // border: 4px solid blue;
    // border-bottom: 1px solid red;
  }

  &-logos {
    display: flex;
    gap: 20px;
    margin: 0 auto;
    align-items: flex-start;
    padding: 1rem;

    img {
      height: 4rem;
      width: auto;
    }

    @include respond-to('small') {
      width: 80%;
      padding: 0;
    }

    @include respond-to('wide') {
      width: 60%;
      width: 1000px;

      img {
        height: 6rem;
      }
    }
  }

  h1 {
    line-height: 1;
    // font-size: 13.7vw;
    font-size: 14vw;
    font-weight: normal;
    padding: 1rem;
    text-align: left;
    width: 100%;
    margin-top: 0;

    @include respond-to('small') {
      padding: 0;
      text-align: center;
    }

    @include respond-to('wide') {
      font-size: 178px;
    }
  }

  &-firstview {
    display: flex;
    // border: 2px solid yellow;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    .siteintro-copy {
      font-size: 1.6rem;
      line-height: 1.5;
      margin: 0 auto;
      text-align: left;
      padding: 0 1rem;

      @include respond-to('small') {
        margin: 2rem auto;
        width: 80%;
        font-size: 2rem;
        padding: 0;
      }

      @include respond-to('wide') {
        width: 60%;
        width: 1000px;
      }
    }
  }

  &-copy, p {
    font-size: 1rem;
    line-height: 1.5;
    // max-width: 800px;
    margin: 2rem auto;
    text-align: left;
    padding: 1rem;

    @include respond-to('small') {
      width: 80%;
      font-size: 1.4rem;
      padding: 0;
    }

    @include respond-to('wide') {
      width: 60%;
      width: 1000px;
    }

    &-small {
      font-size: 0.8rem;
      margin-top: 0;
      // padding-top: 0;

      p {
        width: 100%;
        font-size: inherit;
      }

      @include respond-to('small') {
        font-size: 1rem;
        margin-top: 2rem;
      }
    }

    p { //inside parent no styling
      padding: 0;
      margin: 0;
    }
  }
}
