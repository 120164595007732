.topic-wrapper {
  display: none;
}

.topic-intro {
  font-size: var(--copy-large-size);
  color: var(--local-foreground);
  width: 100%;
  // margin: 8rem auto;
  margin: 100px auto;
  max-width: var(--max-width-l);
  text-align: left;

  @include respond-to(wide) {
    width: calc(90% - 2rem);
  }
}

.topic-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 50px;
  align-items: center;
  text-align: left;
  width: 100%;
  max-width: var(--max-width-s);
  margin: 0 auto;

  @include respond-to(medium) {
    width: calc(90% - 2rem);
    max-width: var(--max-width-m);
  }

  .topic-article-teaser {
    color: var(--local-foreground);
    border-bottom: 2px solid var(--local-foreground);
    display: grid;
    align-items: start;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding-bottom: 50px;

    @include respond-to(small) {
      gap: 2rem;
    }

    &-slideshow {
      .topic-article-cover {
        &:before {
          content: " ";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          transform: rotate(5deg);
          border-radius: 16px;
          background-color: rgba(215,215,215,0.5);
        }
      }
    }

    @include respond-to(small) {
      grid-template-columns: 1fr 2fr;
    }

    &:last-child {
      border-bottom: none;
    }

    .text-button {
      font-size: var(--pill-size);
      margin-top: 12px;
      display: inline-block;
      font-weight: bold;
    }
  }

  .topic-article-title {
    line-height: 1.2;
    margin-bottom: 12px;
    hyphens: auto;
  }

  .topic-article-excerpt {
    font-size: var(--copy-small-size);
  }

  .topic-article-cover {
    // object-fit: cover;
    width: 100%;
    position: relative;

    img {
      border-radius: 16px;
      display: inline-block;
      position: relative;
      z-index: 10;
      object-fit: cover;
      aspect-ratio: 3/2;
    }
  }

  .topic-article-category {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;

    .category-dot {
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }

    &--lifecycle .category-dot {
      background-color: #FAFAFA;
      border: 1px solid #c0c0c0;
    }
    &--economy .category-dot {
      background-color: #756d94;
    }
    &--mobility .category-dot {
      background-color: #FF7E6C;
    }
    &--ressources .category-dot {
      background-color: #361969;
    }
    &--neighborhood .category-dot {
      background-color: #B0D0FF;
    }
    &--planning .category-dot {
      background-color: #FBF08A;
      border: 1px solid #c0c0c0;
    }
    &--controlling .category-dot {
      background-color: #e7e2f0;
      border: 1px solid #c0c0c0;
    }
  }
}

