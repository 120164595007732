main {
  counter-reset: footnotes;
}

/*article-footnote {
  color: currentColor;
  counter-increment: footnotes;
  // text-decoration: underline solid #FF4040;
}

article-footnote::after {
  content: counter(footnotes);
  color: black;
  margin: 0 8px;
  display: inline-block;
  line-height: 20px;
  border: 1px solid black;
  font-size: 12px;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  transition: background-color 200ms, color 200ms;

  &:hover {
    background-color: #000;
    color: #fff;
  }
}*/

.article .article-footnote {
  color: black;
}

.article-footnote {
  color: black;
  margin: 0 8px;
  display: inline-block;
  line-height: 155%;
  border: 1px solid black;
  font-size: 12px;
  font-style: normal  ;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  transition: background-color 200ms, color 200ms;
  background-image: none;
  transform: translateY(-2px);
  text-decoration: none !important;

  &::after {
    content: " ";
    display: none !important;
  }

  &:hover {
    background-color: #000;
    color: #fff;
  }
}


.footnotes-source {
  width: calc(100% - 2rem);
  max-width: var(--max-width-l);
  color: var(--local-foreground);
  // border: 1px solid red;
  border-top: 2px solid var(--local-foreground);
  margin: 4rem auto 0;
  // padding: 2rem 0;
  padding: 50px 0;
  font-size: 1rem;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  gap: 20px;

  @include respond-to('small') {
    grid-template-columns: 2fr 10fr;
  }

  p {
    margin: 0;
    word-break: break-word;
  }

  &-index {
    color: #000;
    width: 20px;
    height: 20px;
    text-align: center;
    border: 1px solid #000;
    border-radius: 50%;
    // margin: 0 8px 0 0;
    margin: 0;
    flex-shrink: 0;
    font-size: 12px;
    line-height: 20px;
    display: inline-block;
    transition: background-color 200ms, color 200ms;

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }

  &-list {
    width: 100%;
    // border: 1px solid blue;

    li {
      display: flex;
      gap: 20px;
      margin-bottom: 1rem;
      align-items: flex-start;
    }

    p {
      margin: 0;
    }
  }
}
