.footer {
  margin: 0 auto;
  // margin-top: 4rem;
  width: calc(100% - 2rem);
  max-width: var(--max-width-l);
  padding: 66px 0;
  // background-color: var(--local-background);
  color: var(--local-foreground);
  border-top: 2px solid var(--local-foreground);
  font-size: 1rem;

  // @include respond-to('medium') {
  //   margin-top: 100px;
  // }

  &-credits {
    display: flex;
    text-align: left;
    gap: 2rem;
    flex-direction: column;
    // text-align: center;
    justify-content: center;

    @include respond-to(wide) {
      justify-content: flex-start;
      flex-direction: row;
      text-align: left;
    }
  }

  &-links {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    flex-direction: column;

    @include respond-to(small) {
      flex-direction: row;
    }
  }

  &-logos {
    display: flex;
    // justify-content: center;
    gap: 1rem;

    svg {
      display: inline;
    }

    .wuestenrot-stiftung-logo {
      color: #CA0632;
    }

    .hft-logo {
      &-background {
        display: block;
      }

      &-outline {
        display: none;
      }
    }

    &.is-white {
      .wuestenrot-stiftung-logo {
        color: white;
      }

      .hft-logo {
        &-background {
          display: none;
        }

        &-outline {
          display: block;
        }
      }
    }
  }
}

.siteintroactive .footer {
  opacity: 0;
  pointer-events: none;
}
